/** @format */
/******************** stylesheet *************************/
@mixin fieldHeader {
	//styleName: Field Header;
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: -0.06em;
}

@mixin fieldUnfilled {
	//styleName: Field unfilled;
	font-family: Montserrat;
	font-size: 16px;
	font-style: italic;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: -0.06em;
}

@mixin tileHeader {
	//styleName: Tile Header;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: -0.06em;
}

@mixin buttonA {
	height: 50px;
	left: 0px;
	top: 0px;
	border-radius: 5px;
	border: 0.5px solid #000000;
	background: #702632;
	@include tileHeader();
	color: white;
}

/******************** Start: TopPage **********************/
.topPage {
	height: 90vh;
	width: 100%;
	background: #333333;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	video {
		height: 90vh;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		object-fit: cover;
		opacity: 0.5;
		overflow: hidden;
	}

	.topPage_content {
		z-index: 1000;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 40px 10px 0px 10px;
		gap: 40px;
		width: 1024px;

		@media (max-width: 1024px) {
			width: 100%;
		}

		.rectangle {
			width: 100%;
			height: 40px;
			background: #702632;
		}

		.title {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			color: #ffffff;
			width: 100%;

			.small {
				font-family: Montserrat;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.06em;
				text-align: left;
			}

			.large {
				font-family: Montserrat;
				font-size: 96px;
				font-style: normal;
				font-weight: 300;
				line-height: 96px;
				letter-spacing: -0.06em;
				text-align: left;

				@media (max-width: 1024px) {
					font-size: 60px;
					line-height: 60px;
					margin-top: 20px;
				}

				b {
					font-weight: 700;
				}
			}
		}
	}
}
/******************** End: TopPage **********************/

/******************** Start: Waitlist **********************/
.waitlist {
	background: #242424;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 50px;
	@media (max-width: 1024px) {
		padding: 30px 20px 50px 20px;
	}

	.dropDown {
		width: 100%;
		button {
			background: none;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid #8f8f8f;
			border-radius: 5px;
			padding: 15px 10px;
			box-sizing: border-box;
			font-family: Montserrat;
			font-size: 11px;
			font-style: normal;
			font-weight: 400;
			line-height: 12px;
			letter-spacing: -0.06em;
			text-align: left;
			color: white;

			img {
				width: 16x;
				height: 16px;
				transition: transform 0.3s;
			}

			.dropped {
				transform: rotate(-180deg);
			}

			input {
				background: none;
				width: 100%;
				border: none;
				margin: 0px;
				color: #ffffff;
				font-family: Montserrat;
				font-weight: 500;
				font-size: 16px;

				&:focus {
					outline: none;
				}

				&::placeholder {
					@include fieldUnfilled();
					text-align: left;
					color: #8f8f8f;
				}
			}
		}

		.options {
			margin-top: 10px;
			background: #c4c4c4;
			border-radius: 5px;
			.option {
				padding: 10px;
				border-bottom: 1px solid #8f8f8f;
				font-family: Montserrat;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 15px;
				letter-spacing: -0.06em;
				text-align: left;
			}
		}
	}

	.thankyou {
		padding: 40px 0px;
		display: flex;
		flex-direction: column;
		gap: 40px;
		align-items: flex-start;

		.title {
			font-family: Montserrat;
			font-size: 60px;
			font-style: normal;
			font-weight: 400;
			line-height: 74px;
			letter-spacing: -0.06em;
			text-align: left;
			color: white;
			@media (max-width: 1024px) {
				font-size: 45px;
			}

			span {
				color: #d4af37;
				font-weight: 300;
			}

			b {
				color: #d4af37;
				font-weight: 700;
			}
		}

		.desc {
			font-family: Montserrat;
			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: 39px;
			letter-spacing: -0.06em;
			text-align: left;
			color: white;
		}

		.button {
			width: 100%;
			button {
				height: 60px;
				width: 460px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: Montserrat;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 29px;
				letter-spacing: 0em;
				text-align: center;
				background: #702632;
				color: white;
				border: none;
				border-radius: 5px;
				box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
				transition: box-shadow 0.2s, transform 0.5s;

				&:hover {
					transform: translateY(-5%);
					box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.487);
				}
				@media (max-width: 1024px) {
					width: 100%;
				}
			}
		}
	}

	.waitlist_description {
		width: 1024px;
		font-family: Montserrat;
		font-size: 28px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px;
		letter-spacing: -0.06em;
		text-align: left;
		color: white;
		padding: 0px 10px;

		@media (max-width: 1024px) {
			width: 100%;
			font-size: 20px;
			padding: 0px 25px;
		}
	}

	.waitlist_details {
		display: grid;
		grid-template-columns: 35% 65%;
		width: 1024px;
		padding-top: 40px;
		@media (max-width: 1024px) {
			width: 100%;
			grid-template-columns: auto;
		}

		.waitlist_input {
			margin-top: 10px;
			border-right: 1px solid#8F8F8F;
			padding-right: 30px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media (max-width: 1024px) {
				border-right: none;
				padding: 0px;
				width: 100%;
			}

			.inputs {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 15px;

				.input {
					border: 1px solid #8f8f8f;
					border-radius: 5px;
					width: 100%;
					padding: 5px 10px;
					box-sizing: border-box;

					p {
						@include fieldHeader();
						text-align: left;
						color: #ffffff;
						margin: 0px;
					}

					input {
						background: none;
						width: 100%;
						border: none;
						margin: 7px 0px;
						color: #ffffff;
						font-family: Montserrat;
						font-weight: 500;
						font-size: 16px;

						&:focus {
							outline: none;
						}

						&::placeholder {
							@include fieldUnfilled();
							text-align: left;
							color: #8f8f8f;
						}
					}
				}

				.error {
					border: 1px solid #ff0000;
					p {
						color: red;
					}
				}
			}

			.button {
				width: 100%;

				button {
					@include buttonA();
					width: 100%;
					@media (max-width: 1024px) {
						margin-top: 20px;
					}

					transition: box-shadow 0.2s, transform 0.5s;

					&:hover {
						transform: translateY(-5%);
						box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.487);
					}
				}
			}
		}

		.waitlist_prizes {
			padding-left: 30px;
			@media (max-width: 1024px) {
				padding: 0px 10px;
				margin-top: 50px;
			}
			.headTitle {
				font-family: Montserrat;
				font-size: 25px;
				font-style: normal;
				font-weight: 500;
				line-height: 39px;
				letter-spacing: -0.06em;
				text-align: left;
				color: white;
			}

			.details {
				display: flex;
				margin-top: 30px;
				display: grid;
				grid-template-columns: 40% 60%;
				align-items: flex-start;
				gap: 50px;

				@media (max-width: 1024px) {
					grid-template-columns: auto;
				}

				.membership {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.membership_text {
						font-family: Montserrat;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 31px;
						letter-spacing: -0.06em;
						text-align: center;
						color: white;
					}

					.membership_logo {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						height: 350px;

						@media (max-width: 1024px) {
							height: auto;
						}

						.membership_circle {
							border: 1px solid #ffffff;
							border-radius: 50%;
							width: 120px;
							height: 120px;
							font-family: Montserrat;
							font-size: 10px;
							font-style: normal;
							font-weight: 600;
							line-height: 12px;
							letter-spacing: -0.06em;
							text-align: center;
							color: #cccccc;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;

							b {
								font-family: Montserrat;
								font-size: 24px;
								font-style: normal;
								font-weight: 700;
								line-height: 29px;
								letter-spacing: -0.06em;
								text-align: center;
								color: #cd7f32;
							}
						}
					}

					.logo_text {
						margin-top: 10px;
						font-family: Montserrat;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 24px;
						letter-spacing: -0.06em;
						text-align: center;
						color: #ffffff;
					}
				} //end of membership

				.prizes {
					.prizes_text {
						font-family: Montserrat;
						font-family: Montserrat;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 31px;
						letter-spacing: -0.06em;
						text-align: center;
						color: white;
					}

					.prizes_box {
						margin-top: 20px;
						border: 1px solid #ffffff;
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						padding: 20px 30px;
						border-radius: 25px;

						.products {
							font-family: Montserrat;
							font-size: 20px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px;
							letter-spacing: -0.06em;
							text-align: center;
							color: white;
							display: flex;
							flex-direction: column;
							margin-bottom: 10px;

							@media (max-width: 1024px) {
								font-size: 16px;
							}
							b {
								font-weight: 600;
							}

							.image {
								border: 1px solid #ffffff;
								border-radius: 50%;
								width: 120px;
								height: 120px;
								margin-bottom: 5px;
								display: flex;
								align-items: center;
								justify-content: center;

								.ipad {
									width: 75px;
								}
								.airpod {
									width: 120px;
								}
								.googleMini {
									width: 65px;
									transform: translateY(-10%);
								}
							}

							.lt {
								font-family: Montserrat;
								font-size: 48px;
								font-style: normal;
								font-weight: 700;
								line-height: 59px;
								letter-spacing: -0.06em;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}

/******************** End: Waitlist **********************/

/******************** Start: Econommi **********************/
.econommiSection {
	padding: 30px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 1024px) {
		padding: 30px 15px;
		box-sizing: border-box;
	}

	.econommi_desc {
		font-family: Montserrat;
		font-size: 28px;
		font-style: normal;
		font-weight: 400;
		line-height: 34px;
		letter-spacing: -0.06em;
		text-align: left;
		width: 1024px;
		box-sizing: border-box;
		@media (max-width: 1024px) {
			width: 100%;
			font-size: 20px;
			line-height: 30px;
		}
	}

	.econommi_mockup {
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 800px;
			@media (max-width: 1024px) {
				width: 600px;
			}
		}
	}

	hr {
		height: 1px;
		background: #8f8f8f;
		width: 1024px;
		@media (max-width: 1024px) {
			width: 100%;
			margin: none;
		}
	}

	.tokenizedLeasing {
		padding: 30px 0px 105px 0px;
		display: flex;
		flex-direction: column;
		width: 1024px;
		@media (max-width: 1024px) {
			width: 100%;
			padding: 50px 0px;
		}
		.title {
			font-family: Montserrat;
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 44px;
			letter-spacing: -0.06em;
			text-align: left;
			margin-bottom: 45px;

			@media (max-width: 1024px) {
				font-size: 32px;
				line-height: 38px;
			}
		}
		.tokenizedDetails {
			border: 1px solid #00000080;
			box-sizing: border-box;
			padding: 30px;
			border-radius: 25px;
			display: flex;
			flex-direction: column;
			gap: 70px;

			@media (max-width: 1024px) {
				width: 100%;
				gap: 50px;
				padding: 30px 20px;
			}
			.detail_1,
			.detail_2,
			.detail_3 {
				display: grid;
				grid-template-columns: 18% 80%;
				align-items: center;
				@media (max-width: 1024px) {
					grid-template-columns: auto;
					gap: 20px;
				}

				.number {
					width: 140px;
					height: 140px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: Montserrat;
					font-size: 72px;
					font-style: normal;
					font-weight: 900;
					line-height: 88px;
					letter-spacing: 0em;
					text-align: center;
					color: white;
					background: linear-gradient(180deg, #ed4d4d 0%, rgba(237, 77, 77, 0) 144.64%);
					@media (max-width: 1024px) {
						width: 70px;
						height: 70px;
						font-size: 36px;
					}
				}

				.details {
					font-family: Montserrat;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 29px;
					letter-spacing: 0em;
					text-align: left;

					@media (max-width: 1024px) {
						font-size: 16px;
						line-height: 22px;
					}
				}
			}
		}
	} // end of tokenizedLeasing

	.leaseTokens {
		padding-top: 100px;
		width: 1024px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 30px;
		@media (max-width: 1024px) {
			width: 100%;
			padding-top: 60px;
		}

		.leaseTokens_title {
			font-family: Montserrat;
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: 44px;
			letter-spacing: -0.06em;
			text-align: left;

			@media (max-width: 1024px) {
				font-size: 32px;
				line-height: 38px;
			}
		}

		.leaseTokens_1,
		.leaseTokens_2 {
			font-family: Montserrat;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: 38px;
			letter-spacing: -0.06em;
			text-align: left;

			@media (max-width: 1024px) {
				font-size: 18px;
				line-height: 28px;
			}

			p {
				margin: 10px 0px;
				display: flex;
			}

			span {
				text-decoration: underline;
			}
		}

		.leaseTokens_3 {
			.bold {
				font-family: Montserrat;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 29px;
				letter-spacing: -0.06em;
				text-align: left;
			}

			p {
				margin-left: 40px;
				font-family: Montserrat;
				font-family: Montserrat;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 29px;
				letter-spacing: -0.06em;
				text-align: left;
				display: flex;
				align-items: center;
				gap: 10px;

				@media (max-width: 1024px) {
					font-size: 18px;
					line-height: 26px;
					margin-left: 10px;
				}
			}

			b {
				font-weight: 600;
			}

			span {
				text-decoration: underline;
			}
		}

		.leaseTokens_4 {
			padding-top: 50px;
			display: flex;
			flex-direction: row;
			gap: 30px;
			margin-bottom: 50px;

			@media (max-width: 1024px) {
				flex-wrap: wrap;
				gap: 10px;
				margin-bottom: 40px;
				padding-top: 30px;
			}

			.econ {
				box-sizing: border-box;
				width: 300px;
				height: 210px;
				background: linear-gradient(137.59deg, #2c2d29 17.21%, #6a0a6c 98.09%);
				border: 1px solid #0000001a;
				border-radius: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: Ubuntu;
				font-size: 42px;
				font-style: normal;
				font-weight: 700;
				letter-spacing: 0em;
				text-align: center;
				color: white;

				@media (max-width: 1024px) {
					width: 100px;
					height: 90px;
					border-radius: 15px;
					font-size: 22px;
				}
			}

			.econ_desc {
				.title {
					font-family: Ubuntu;
					font-size: 36px;
					font-style: normal;
					font-weight: 700;
					line-height: 48px;
					letter-spacing: 0em;

					@media (max-width: 1024px) {
						font-size: 32px;
					}
				}

				.desc {
					margin-top: 15px;
					font-family: Montserrat;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 38px;
					letter-spacing: -0.06em;
					text-align: left;
					@media (max-width: 1024px) {
						margin-top: 5px;
						font-size: 18px;
						line-height: 26px;
					}
				}
			}
		}
	}
}

/******************** End: Econommi **********************/

/******************** Start: Membership **********************/
.membership_section {
	background: #242424;
	display: flex;
	justify-content: center;
	@media (max-width: 1024px) {
		padding: 0px 15px;
	}

	.membership {
		width: 1024px;
		padding: 50px 0px;
		@media (max-width: 1024px) {
			width: 100%;
		}

		.membership_title {
			font-family: Montserrat;
			font-size: 40px;
			font-style: normal;
			font-weight: 700;
			line-height: 49px;
			letter-spacing: -0.06em;
			text-align: left;
			margin-bottom: 30px;
			color: white;

			@media (max-width: 1024px) {
				font-size: 32px;
			}
		}

		.membership_desc {
			font-family: Montserrat;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: 29px;
			letter-spacing: -0.06em;
			text-align: left;
			color: white;

			@media (max-width: 1024px) {
				font-size: 20px;
			}
		}

		.membership_table {
			background: #ffffff;
			border-radius: 25px;
			margin-top: 30px;
			padding: 20px;

			@media (max-width: 1024px) {
				padding: 15px;
			}

			hr {
				border: 1px solid #000000;
				opacity: 0.4;
				@media (max-width: 1024px) {
					width: 100%;
				}
			}

			.table_top {
				display: grid;
				align-items: center;
				grid-template-columns: 70% 30%;

				@media (max-width: 1024px) {
					width: 100%;
					grid-template-columns: 65% 35%;
				}
				.text {
					font-family: Montserrat;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 29px;
					letter-spacing: 0em;
					text-align: left;

					@media (max-width: 1024px) {
						font-size: 15px;
					}
				}
				.colors {
					display: grid;
					grid-template-columns: auto auto auto;
					font-family: Montserrat;
					font-size: 18px;
					font-style: italic;
					font-weight: 700;
					line-height: 22px;
					letter-spacing: -0.03em;
					text-align: center;
					opacity: 0.5;
					gap: 30px;

					@media (max-width: 1024px) {
						font-size: 9px;
						gap: 10px;
					}
				}
			}

			.table_1 {
				display: grid;
				grid-template-columns: 70% 30%;
				padding: 20px 0px 20px 15px;
				@media (max-width: 1024px) {
					width: 100%;
					padding: 10px 0px;
					grid-template-columns: 65% 35%;
				}
				.text {
					font-family: Montserrat;
					font-size: 18px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
					letter-spacing: -0.01em;
					text-align: left;
					display: flex;
					align-items: center;
					gap: 7px;

					@media (max-width: 1024px) {
						font-size: 14px;
						display: inline-block;
					}
					img {
						width: 43px;
						margin-right: 15px;

						@media (max-width: 1024px) {
							margin-right: 0px;
							width: 0px;
						}
					}

					span {
						text-decoration: underline;
					}
				}

				.colors {
					display: grid;
					gap: 30px;
					grid-template-columns: auto auto auto;
					@media (max-width: 1024px) {
						gap: 10px;
					}
					.bronze {
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							background: #cd7f32;
							width: 32px;
							height: 32px;
							border-radius: 10px;

							@media (max-width: 1024px) {
								width: 5px;
							}
						}
					}
					.silver {
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							background: #a2abbf;
							width: 32px;
							height: 32px;
							border-radius: 10px;
							@media (max-width: 1024px) {
								width: 5px;
							}
						}
					}
					.gold {
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							background: #9f8328;
							width: 32px;
							height: 32px;
							border-radius: 10px;
							@media (max-width: 1024px) {
								width: 5px;
							}
						}
					}
					.none {
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							background: #ffffff;
							width: 32px;
							height: 32px;
							border-radius: 10px;
							@media (max-width: 1024px) {
								width: 5px;
							}
						}
					}
				}
			}
		} //end of membership table

		.membership_type {
			margin: 60px 0px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 40px;
			.type {
				.title {
					font-family: Montserrat;
					font-size: 24px;
					font-style: italic;
					font-weight: 700;
					line-height: 29px;
					letter-spacing: -0.03em;
					text-align: left;
				}
				.gold {
					color: #9f8328;
				}
				.silver {
					color: #a2abbf;
				}
				.bronze {
					color: #cd7f32;
				}

				p {
					font-family: Montserrat;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.03em;
					text-align: left;
					color: white;

					@media (max-width: 1024px) {
						font-size: 14px;
						line-height: 18px;
					}

					span {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

/******************** End: Membership **********************/

/******************** Start: Lending **********************/
.lending {
	display: flex;
	justify-content: center;
	padding-top: 30px;
	@media (max-width: 1024px) {
		padding: 40px 15px;
	}
	.lending_section {
		width: 1024px;
		@media (max-width: 1024px) {
			width: 100%;
		}

		.lending_main {
			.title {
				font-family: Montserrat;
				font-size: 40px;
				font-style: normal;
				font-weight: 700;
				line-height: 49px;
				letter-spacing: -0.06em;
				text-align: left;

				@media (max-width: 1024px) {
					font-size: 32px;
				}
			}
			.detail {
				margin-top: 10px;
				font-family: Montserrat;
				font-size: 25px;
				font-style: normal;
				font-weight: 400;
				line-height: 40px;
				letter-spacing: -0.06em;
				text-align: left;

				@media (max-width: 1024px) {
					font-size: 18px;
					line-height: 32px;
				}

				b {
					font-weight: 600;
				}
				span {
					text-decoration: underline;
				}
			}

			.encore {
				margin-top: 50px;
				p {
					font-family: Montserrat;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 40px;
					letter-spacing: -0.06em;
					text-align: left;
					margin: 20px 0px;

					@media (max-width: 1024px) {
						font-size: 18px;
						line-height: 32px;
					}
				}
			}

			.bubbles {
				margin-top: 90px;
				display: flex;
				justify-content: center;
				gap: 80px;
				flex-wrap: wrap;
				margin-bottom: 90px;

				.bubble_1 {
					.bola {
						width: 165px;
						height: 165px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 1px solid #8f8f8f;
						font-family: Montserrat;
						font-size: 35px;
						font-style: normal;
						font-weight: 600;
						line-height: 35px;
						letter-spacing: -0.06em;
						text-align: center;
						border-radius: 50%;
						color: white;
					}
					.blue {
						background: linear-gradient(135deg, #1117b1 0%, #cdcdcd 100%);
					}
					.brown {
						background: linear-gradient(135deg, #04681a 37.59%, #cdcdcd 100%);
					}
					.gold {
						background: linear-gradient(
								136.73deg,
								rgba(159, 131, 40, 0) 0%,
								rgba(205, 127, 50, 0.9) 83.08%
							),
							linear-gradient(135.9deg, #9f8328 0%, #f4f3f2 111.78%);
					}

					.desc {
						font-family: Montserrat;
						text-align: center;
						margin-top: 15px;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: 25px;
						letter-spacing: -0.06em;
						text-align: center;
					}
				}
			} //end of bubbles
		} //end of landing main

		hr {
			height: 2px;
			background: #8f8f8f;
		}

		.learnMore {
			padding-top: 70px;
			padding-bottom: 80px;
			.bigTitle {
				font-family: Montserrat;
				font-size: 64px;
				font-style: normal;
				font-weight: 700;
				line-height: 78px;
				letter-spacing: 0em;
				text-align: left;
				padding: 20px 0px;

				@media (max-width: 1024px) {
					font-size: 40px;
					line-height: 40px;
				}
			}

			.desc {
				font-family: Montserrat;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 44px;
				letter-spacing: 0em;
				text-align: left;
				padding: 20px 0px 30px 0px;

				@media (max-width: 1024px) {
					font-size: 20px;
					line-height: 30px;
				}
			}

			a {
				text-decoration: none;
				.website {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #702632;
					width: 460px;
					height: 50px;
					align-items: center;
					border-radius: 5px;
					font-family: Montserrat;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 29px;
					letter-spacing: 0em;
					text-align: center;
					color: white;

					@media (max-width: 1024px) {
						width: 100%;
						font-size: 20px;
					}
				}
			}
		}
	}
}

/******************** End: Leading **********************/

/******************** Start: Footer **********************/
.footer {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100px;
	width: 100%;
	background: #702632;
	padding: 0px 20px;

	@media (max-width: 1024px) {
		flex-wrap: wrap;
		height: auto;
		gap: 20px;
	}

	a {
		text-decoration: none;
	}
	.title {
		img {
			width: 249px;
			margin: 10px 0px;

			@media (max-width: 1024px) {
				width: 180px;
				margin: 15px 0px 0px 0px;
			}
		}
		.copyright {
			font-family: Montserrat;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: -0.06em;
			text-align: left;
			color: white;

			@media (max-width: 1024px) {
				font-size: 10px;
			}
		}
	}

	.socials {
		display: flex;
		gap: 20px;
		align-items: center;

		@media (max-width: 1024px) {
			justify-content: flex-end;
			width: 100%;
			padding-bottom: 10px;
		}

		a {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

/******************** End: Footer **********************/
